body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f9fafb;
}

/* Hide scrollbar for the entire page */

/* For modern browsers */
body::-webkit-scrollbar {
  width: 5px;
}
body::-webkit-scrollbar-track {
  background: #f9fafb; /* Color of the track */
  border-radius: 10px; /* Rounded corners for the track */
}

/* The draggable part of the scrollbar */
body::-webkit-scrollbar-thumb {
  background: #d8d3d3; /* Color of the thumb */
  border-radius: 10px; /* Rounded corners for the thumb */
}

html {
  scroll-behavior: smooth !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

/* landing page styling */
.hero_section_vector {
  width: 36rem;
  height: 37rem;
  scale: 0.9;
}

/* Background image */
.hero_bg {
  /* background: url('../public/bg.svg') no-repeat center center fixed;
  background-size: cover; */
  /* background-color: aqua; */
  background: #1d4ed8;
  /* background: #dcecf9; */
}

.faqcard {
  box-shadow: none !important;
}

.opacity-5 {
  opacity: 0.09 !important;
}

.grad_button {
  background: hsla(275, 100%, 20%, 1);
  transition-duration: 5s;
  background: linear-gradient(
    360deg,
    hsla(275, 100%, 20%, 1) 0%,
    hsla(275, 88%, 41%, 1) 58%,
    hsla(281, 60%, 59%, 1) 100%
  );
  background: -moz-linear-gradient(
    360deg,
    hsla(275, 100%, 20%, 1) 0%,
    hsla(275, 88%, 41%, 1) 58%,
    hsla(281, 60%, 59%, 1) 100%
  );
  background: -webkit-linear-gradient(
    360deg,
    hsla(275, 100%, 20%, 1) 0%,
    hsla(275, 88%, 41%, 1) 58%,
    hsla(281, 60%, 59%, 1) 100%
  );
  transition: 2s;
  -webkit-transition: 2s;
  -moz-transition: 2s;
  -ms-transition: 2s;
  -o-transition: 2s;
  filter: progid: DXImageTransform.Microsoft.gradient(    startColorstr= '#3A0064',    endColorstr= '#780DC4',    GradientType=1  );
}

.grad_button:hover {
  background: hsla(275, 100%, 20%, 1);

  background: linear-gradient(
    180deg,
    hsla(275, 100%, 20%, 1) 0%,
    hsla(275, 88%, 41%, 1) 58%,
    hsla(281, 60%, 59%, 1) 100%
  );
  background: -moz-linear-gradient(
    180deg,
    hsla(275, 100%, 20%, 1) 0%,
    hsla(275, 88%, 41%, 1) 58%,
    hsla(281, 60%, 59%, 1) 100%
  );
  background: -webkit-linear-gradient(
    180deg,
    hsla(275, 100%, 20%, 1) 0%,
    hsla(275, 88%, 41%, 1) 58%,
    hsla(281, 60%, 59%, 1) 100%
  );
  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#3A0064", endColorstr="#780DC4", GradientType=1 );
}

.title_text {
  background: -webkit-linear-gradient(#3a0064, #780dc4, #ae59d5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.statsCardbg {
  background: hsla(275, 100%, 20%, 1);
  transition-duration: 5s;
  background: linear-gradient(
    360deg,
    hsla(275, 100%, 20%, 1) 0%,
    hsla(275, 88%, 41%, 1) 58%,
    hsla(281, 60%, 59%, 1) 100%
  );
  background: -moz-linear-gradient(
    360deg,
    hsla(275, 100%, 20%, 1) 0%,
    hsla(275, 88%, 41%, 1) 58%,
    hsla(281, 60%, 59%, 1) 100%
  );
  background: -webkit-linear-gradient(
    360deg,
    hsla(275, 100%, 20%, 1) 0%,
    hsla(275, 88%, 41%, 1) 58%,
    hsla(281, 60%, 59%, 1) 100%
  );
  transition: 2s;
  -webkit-transition: 2s;
  -moz-transition: 2s;
  -ms-transition: 2s;
  -o-transition: 2s;
  filter: progid: DXImageTransform.Microsoft.gradient(    startColorstr= '#3A0064',    endColorstr= '#780DC4',    GradientType=1  );
  -webkit-filter: progid: DXImageTransform.Microsoft.gradient(    startColorstr= '#3A0064',    endColorstr= '#780DC4',    GradientType=1  );
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  /* Center slide text vertically */
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
}

.swiper-button-prev,
.swiper-button-next {
  color: #780dc4 !important;
}

.modal {
  background-color: #000000e6;
}
